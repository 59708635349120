import Markdown from 'markdown-to-jsx';
import { Alerts, Alert } from '@dx-ui/osc-alert';
import { Link } from '@dx-ui/osc-link';
import AlertDialog from '../alert/alert.dialog';
import { useTranslation } from 'next-i18next';

import type { HotelAlertType } from '@dx-ui/gql-types';
import type { GetHotelAlertsQuery } from '../../generated/types';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type TWrappedPropertyAlerts = {
  messages?: NonNullable<NonNullable<GetHotelAlertsQuery['hotelPageTemplate']>['hotel']>['alerts'];
};

const getStatus = (type: HotelAlertType): 'normal' | 'urgent' | 'emergency' => {
  switch (type) {
    case 'emergency':
      return 'emergency';
    case 'alert':
    case 'maintenance':
      return 'urgent';
    case 'announcement':
    default:
      return 'normal';
  }
};

const WrappedPropertyAlerts: React.FC<TWithCustomTheme<TWrappedPropertyAlerts>> = ({
  messages,
  wrapperClass,
}) => {
  const { t } = useTranslation('alerts');

  return (messages?.length ?? 0) > 0 ? (
    <div className={wrapperClass}>
      <Alerts label={t('alerts')}>
        {messages?.map(({ link, type, description, headline, ...messageProps }) => (
          <Alert
            key={`property-alert-${headline}`}
            title={headline || ''}
            {...(type && { status: getStatus(type) })}
          >
            {link && !description ? (
              <Link
                url={link?.url || ''}
                adaDescription={link.adaDescription || ''}
                isNewWindow={link.isNewWindow || false}
                label={link.label || ''}
              >
                {link.label}
              </Link>
            ) : (
              !!description && (
                <AlertDialog
                  label={t('readMessage')}
                  ariaLabel={headline || ''}
                  title={headline || ''}
                  content={<Markdown>{description}</Markdown>}
                  link={{ ...link, url: link?.url || '' }}
                  alertMessages={messages}
                  {...messageProps}
                />
              )
            )}
          </Alert>
        ))}
      </Alerts>
    </div>
  ) : null;
};

export { WrappedPropertyAlerts };
