import type * as React from 'react';
import { useCycle } from '@dx-ui/osc-cycle';
import type { Alerts } from './alerts';

const AlertsContent: React.FC<React.PropsWithChildren<Alerts>> = ({ children }) => {
  const { cycleId } = useCycle();

  return (
    <div id={cycleId} aria-live="polite" className="w-full">
      {children}
    </div>
  );
};

export { AlertsContent };
export default AlertsContent;
