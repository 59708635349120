import * as React from 'react';
import type { Pagination } from '@dx-ui/osc-pagination';
import { Alert } from './alert';
import { AlertsContent } from './alerts.content';
import { AlertsControls } from './alerts.controls';
import { Cycle, type CycleProps } from '@dx-ui/osc-cycle';

type Alerts = Pick<CycleProps, 'onChangeIndex'> &
  Pick<Pagination, 'label' | 'loop' | 'hideLabel' | 'current'> &
  React.HTMLAttributes<HTMLDivElement>;

const Alerts: React.FC<React.PropsWithChildren<Alerts>> = ({
  label,
  hideLabel,
  loop,
  children,
  current,
  onChangeIndex,
  ...rest
}) => {
  const validSlides = React.Children.map(
    children,
    (child) => React.isValidElement(child) && child.type === Alert && child
  )?.filter(Boolean);

  const slides = React.Children.map(
    validSlides,
    (child, index) =>
      React.isValidElement(child) &&
      React.cloneElement<Alert>(child as React.ReactElement<React.ComponentProps<typeof Alert>>, {
        index,
      })
  );
  const total = React.Children.count(slides);

  return (
    <Cycle current={current} total={total} onChangeIndex={onChangeIndex}>
      <AlertsContent {...rest}>{slides}</AlertsContent>
      <AlertsControls label={label} hideLabel={hideLabel} loop={loop} />
    </Cycle>
  );
};

export { Alerts };
export default Alerts;
