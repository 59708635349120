import { useGetHotelAlertsQuery } from '../../generated/react-query';
import { WrappedPropertyAlerts } from './property-alerts';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type PropertyAlerts = {
  ctyhocn: string;
};

const PropertyAlerts = ({ ctyhocn, wrapperClass }: TWithCustomTheme<PropertyAlerts>) => {
  const { data, isError, isLoading } = useGetHotelAlertsQuery({
    ctyhocn,
  });
  const messages = data?.hotelPageTemplate?.hotel?.alerts;

  if (messages && !isError && !isLoading) {
    return <WrappedPropertyAlerts messages={messages} wrapperClass={wrapperClass} />;
  }

  return null;
};

export default PropertyAlerts;
