import type * as React from 'react';
import { useCycle } from '@dx-ui/osc-cycle';
import { Pagination } from '@dx-ui/osc-pagination';
import type { Alerts } from './alerts';

const AlertsControls: React.FC<React.PropsWithChildren<Alerts>> = ({
  label = 'Page',
  hideLabel = true,
  loop,
}) => {
  const { hasSlides, cycleId, active, total, onNextClick, onPreviousClick } = useCycle();

  return hasSlides ? (
    <div className="pointer-events-none absolute bottom-0 right-0 pb-1 md:inset-0 md:pb-0">
      <div className="mx-auto flex size-full max-w-screen-2xl items-center justify-end px-4">
        <Pagination
          controls={cycleId}
          label={label}
          hideLabel={hideLabel}
          current={active}
          total={total}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          loop={loop}
        />
      </div>
    </div>
  ) : null;
};
export { AlertsControls };
export default AlertsControls;
